import { SET_GENES } from '../actions/actions';

import {
  CLEAR_RELEVANT_NETWORKS,
  SELECT_NODE,
  SELECT_EDGE,
  SET_NODE_CUTOFF,
  SET_EDGE_CUTOFF,
  SELECT_INTEGRATION,
  RECEIVE_INTEGRATIONS,
  REQUEST_NETWORK,
  RECEIVE_NETWORK,
  RECEIVE_EVIDENCE,
  REQUEST_TERMS,
  RECEIVE_TERMS,
  ADD_NETWORK,
  REMOVE_NETWORK,
  RECEIVE_DATATYPES,
  SELECT_DATATYPES,
  ADD_DATATYPE,
  REMOVE_DATATYPE,
  RECEIVE_MULTINETWORK_EDGE,
  ADD_NETWORK_GROUP,
  RECEIVE_RELEVANT_NETWORKS,
  SET_CURRENT_SEARCH_GENES,
  SET_CURRENT_SEARCH_TEXT,
  SET_CURRENT_SEARCH_INTEGRATION,
  CLEAR_CURRENT_SEARCH,
  SET_SEARCH_TARGET,
  SELECT_GIANT_VERSION,
} from '../actions/NetworkActions';

let networkIdx = 0;

/*
 * Reducers for interacting with user networks
 *
 */

function giantVersion(state = 'v2', action) {
  switch (action.type) {
    case SELECT_GIANT_VERSION:
      return action.giantVersion;
    default:
      return state;
  }
}

function integrations(state = [], action) {
  switch (action.type) {
    case RECEIVE_INTEGRATIONS:
      return action.integrations;
    default:
      return state;
  }
}

function datatypes(state = [], action) {
  switch (action.type) {
    case RECEIVE_DATATYPES:
      return action.datatypes;
    default:
      return state;
  }
}

function evidence(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EVIDENCE:
      return Object.assign({}, state, {
        [action.edge.id]: action.evidence,
      });
    default:
      return state;
  }
}

function edgeNetworks(state = {}, action) {
  switch (action.type) {
    case RECEIVE_MULTINETWORK_EDGE:
      return Object.assign({}, state, {
        [action.edge.id]: action.edges,
      });
    default:
      return state;
  }
}

function annotatedTerms(state = [], action) {
  switch (action.type) {
    case RECEIVE_TERMS:
      return action.terms;
    default:
      return state;
  }
}

function datatypesSelected(state = [], action) {
  switch (action.type) {
    case ADD_DATATYPE:
      return [...state, action.datatype];
    case REMOVE_DATATYPE:
      return [
        ...state.slice(0, action.index),
        ...state.slice(action.index + 1),
      ];
    case SELECT_DATATYPES:
      return action.datatypes;
    default:
      return state;
  }
}

function genesSelected(state = [], action) {
  switch (action.type) {
    case SET_GENES:
      return action.genes;
    default:
      return state;
  }
}

function networkData(
  state = {
    /* NOTE: Cutoffs no long updated in global state
     */
    edgeCutoff: 0.5,
    nodeCutoff: 15,
    selectedNode: null,
    selectedEdge: null,
    selectedIntegration: null,

    isFetching: false,
    isFetchingTerms: false,
    evidence: {},
    edgeNetworks: {},
    selectedDatatypes: [],
    selectedGenes: [],
  },
  action,
) {
  switch (action.type) {
    case REQUEST_NETWORK:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVE_NETWORK:
      return Object.assign({}, state, {
        data: action.network,
        isFetching: false,
      });
    case RECEIVE_EVIDENCE:
      return Object.assign({}, state, {
        evidence: evidence(state.evidence, action),
      });
    case RECEIVE_MULTINETWORK_EDGE:
      return Object.assign({}, state, {
        edgeNetworks: edgeNetworks(state.edgeNetworks, action),
      });
    case REQUEST_TERMS:
      return Object.assign({}, state, {
        isFetchingTerms: true,
      });
    case RECEIVE_TERMS:
      return Object.assign({}, state, {
        annotatedTerms: annotatedTerms(state.annotatedTerms, action),
        isFetchingTerms: false,
      });
    case SET_GENES:
      return Object.assign({}, state, {
        selectedGenes: genesSelected(state.selectedGenes, action),
      });
    case SELECT_EDGE:
      return Object.assign({}, state, {
        selectedEdge: action.selectedEdge,
      });
    case SELECT_NODE:
      return Object.assign({}, state, {
        selectedNode: action.selectedNode,
      });
    case SET_NODE_CUTOFF:
      return Object.assign({}, state, {
        nodeCutoff: action.nodeCutoff,
      });
    case SET_EDGE_CUTOFF:
      return Object.assign({}, state, {
        edgeCutoff: action.edgeCutoff,
      });
    case SELECT_INTEGRATION:
      return Object.assign({}, state, {
        selectedIntegration: action.integration,
      });
    case SELECT_DATATYPES:
    case ADD_DATATYPE:
    case REMOVE_DATATYPE:
      return Object.assign({}, state, {
        selectedDatatypes: datatypesSelected(state.selectedDatatypes, action),
      });
    default:
      return state;
  }
}

function networks(state = [], action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ADD_NETWORK:
      return [
        ...state,
        Object.assign({}, networkData(state[action.id], action), {
          selectedIntegration: action.integration,
          selectedGenes: action.genes,
          id: ++networkIdx, // eslint-disable-line no-plusplus
        }),
      ];
  }

  if (action.id === null || action.id === undefined) return state;

  switch (action.type) {
    // network selections
    case SELECT_EDGE:
    case SELECT_NODE:
    case SET_NODE_CUTOFF:
    case SET_EDGE_CUTOFF:
    case SELECT_INTEGRATION:
    case SELECT_DATATYPES:
    case ADD_DATATYPE:
    case REMOVE_DATATYPE:
    // query gene selection
    case SET_GENES:
    // network retrieval
    case RECEIVE_NETWORK:
    case RECEIVE_EVIDENCE:
    case REQUEST_NETWORK:
    case RECEIVE_MULTINETWORK_EDGE:
    // term enrichment retrieval
    case RECEIVE_TERMS:
      return state.map((network, idx) => {
        if (idx === action.id) {
          return networkData(network, action);
        }
        return network;
      });
    case REMOVE_NETWORK:
      if (!state[action.id].isFetching && !state[action.id].isFetchingTerms)
        return [...state.slice(0, action.id), ...state.slice(action.id + 1)];
      return state;
    default:
      return state;
  }
}

function networkGroups(state = {}, action) {
  if (action.id === null || action.id === undefined) return state;
  switch (action.type) {
    case ADD_NETWORK_GROUP:
      return Object.assign({}, state, {
        [action.id.group]: [],
      });
    case ADD_NETWORK:
    case SELECT_EDGE:
    case SELECT_NODE:
    case SET_NODE_CUTOFF:
    case SET_EDGE_CUTOFF:
    case SELECT_INTEGRATION:
    case SELECT_DATATYPES:
    case ADD_DATATYPE:
    case REMOVE_DATATYPE:
    // query gene selection
    case SET_GENES:
    // network retrieval
    case RECEIVE_NETWORK:
    case RECEIVE_EVIDENCE:
    case REQUEST_NETWORK:
    case REMOVE_NETWORK:
    case RECEIVE_MULTINETWORK_EDGE:
    // term enrichment retrieval
    case RECEIVE_TERMS:
      return Object.assign({}, state, {
        [action.id.group]: networks(
          state[action.id.group],
          Object.assign({}, action, { id: action.id.idx }),
        ),
      });
    default:
      return state;
  }
}

function networkSelection(state = {}, action) {
  // If action doesn't specify an id, ignore
  // If an action specifies a network id, ignore
  if (
    action.id === null ||
    action.id === undefined ||
    action.id.idx !== undefined
  ) {
    return state;
  }

  switch (action.type) {
    case ADD_NETWORK_GROUP:
      return Object.assign({}, state, {
        [action.id.group]: {},
      });
    // network selections
    case SELECT_INTEGRATION:
    case SELECT_DATATYPES:
    case ADD_DATATYPE:
    case REMOVE_DATATYPE:
    // query gene selection
    case SET_GENES:
      return Object.assign({}, state, {
        [action.id.group]: networkData(state[action.id.group], action),
      });
    default:
      return state;
  }
}

function relevantNetworks(state = [], action) {
  switch (action.type) {
    case CLEAR_RELEVANT_NETWORKS:
      return [];
    case RECEIVE_RELEVANT_NETWORKS:
      return action.networks.slice(0);
    default:
      return state;
  }
}

function currentSearch(
  state = {
    currentSearchGenes: [],
    currentSearchText: null, // Must be null to distinguish uninitialized
    searchTarget: 'gene',
  },
  action,
) {
  switch (action.type) {
    case SET_CURRENT_SEARCH_TEXT:
      return Object.assign({}, state, {
        currentSearchText: action.currentSearchText,
      });
    case SET_CURRENT_SEARCH_GENES:
      return Object.assign({}, state, {
        currentSearchGenes: action.currentSearchGenes.slice(0),
      });
    case CLEAR_CURRENT_SEARCH:
      return Object.assign({}, state, {
        currentSearchGenes: [],
        currentSearchText: null,
      });
    case SET_SEARCH_TARGET:
      return Object.assign({}, state, {
        searchTarget: action.searchTarget,
      });
    case SET_CURRENT_SEARCH_INTEGRATION:
      return Object.assign({}, state, {
        currentSearchIntegration: action.currentSearchIntegration,
      });
    default:
      return state;
  }
}

const NetworkReducers = {
  currentSearch,
  datatypes,
  giantVersion,
  integrations,
  networkGroups,
  networkSelection,
  relevantNetworks,
};

export default NetworkReducers;
