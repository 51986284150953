import { BASE_URI } from '../settings';

/*
 * action types
 */
export const RECEIVE_DATASETS = 'RECEIVE_DATASETS';

export const RECEIVE_COMPENDIUM = 'RECEIVE_COMPENDIUM';

export const RECEIVE_DATABASES = 'RECEIVE_DATABASES';

/*
 * action creators
 */
export function receiveCompendium(compendium) {
  return {
    type: RECEIVE_COMPENDIUM,
    compendium,
  };
}

export function receiveDatabases(databases) {
  return {
    type: RECEIVE_DATABASES,
    databases,
  };
}

export function fetchDatabases() {
  return async (dispatch, getState, { fetch }) => {
    const url = `${BASE_URI}databases/`;
    const response = await fetch(url);
    const json = await response.json();
    dispatch(receiveDatabases(json));
  };
}
