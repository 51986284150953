import sha1 from 'js-sha1';

// eslint-disable-next-line import/prefer-default-export
export const getCommunityBodyTag = (
  genes,
  networkSlug,
  giantVersion,
  title = '',
) => {
  const body = `{ "entrez": [${genes
    .map(gene => `"${gene.entrez}"`)
    .sort()}] }`;
  return sha1(`${body}${networkSlug}${giantVersion}${title}`);
};
