/* eslint-disable camelcase */
import { useContext } from 'react';
import {
  ActorRefFrom,
  assign,
  createMachine,
  MachineConfig,
  MachineOptions,
} from 'xstate';
import { useActor } from '@xstate/react';

import { EmptyObject, TissueNetwork } from '../shared/sharedTypes';
import { fetchRelevantNetworks } from '../../actions/NetworkActions';
import { getCommunityBodyTag } from '../community/community_util';
import { BASE_URI, TISSUE_DB, UBERON_DB, HB_API_VERSION } from '../../settings';
import ApplicationContext from '../ApplicationContext';
import { areEntrezListsEqual } from '../../core/util';

type CommunityResult = any;
export type FMDActor = ActorRefFrom<typeof fmdMachine>;

// Utility hooks
export const useFMD = () => {
  const { xstate } = useContext(ApplicationContext);
  const { fmdService } = xstate;
  return useActor(fmdService as FMDActor);
};

const filterTissueIntegrations = (
  integrations: TissueNetwork[],
  currentDb: string,
) => {
  return integrations
    ? integrations.filter(
        integration =>
          integration?.context?.term?.database?.slug === currentDb ||
          integration.slug === 'global',
      )
    : [];
};

export interface FMDMachineContext {
  bodyTag: string | null;
  communityResult: CommunityResult | null;
  entrezList: string[];
  giantVersion: string;
  hasError: boolean;
  integrations: TissueNetwork[];
  relevantNetworks: TissueNetwork[];
  selectedNetworkSlug: string | null;
  selectedTermDbSlug: string | null;
  title: string | null;
}

type FetchCommunityNetworksDoneEvent = {
  type: 'done.invoke.fmd.networksManager.fetchingNetworks:invocation[0]';
  data: {
    integrations: TissueNetwork[];
    relevantNetworks: TissueNetwork[];
  };
};

type FetchCommunityDoneEvent = {
  type: 'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]';
  data: {
    communityResult: CommunityResult;
  };
};

export type FMDMachineEvent =
  | { type: 'RESET' }
  | {
      type: 'FETCH_COMMUNITY';
      bodyTag?: string;
      entrezList?: string[];
      giantVersion?: string;
      selectedNetworkSlug?: string | null;
      title?: string;
    }
  | {
      type: 'SELECT_GIANT_VERSION';
      giantVersion: string;
    }
  | {
      type: 'UPDATE_ENTREZ_LIST';
      entrezList: string[];
    }
  | {
      type: 'UPDATE_SELECTED_NETWORK';
      selectedNetworkSlug: string | null;
    }
  | {
      type: 'UPDATE_SELECTED_TERM_DB';
      selectedTermDbSlug: string;
    }
  | {
      type: 'UPDATE_TITLE';
      title: string;
    }
  | FetchCommunityNetworksDoneEvent
  | FetchCommunityDoneEvent;

export interface FMDStateSchema {
  states: {
    enrichmentDbManager: {
      states: {
        idle: EmptyObject;
      };
    };
    networksManager: {
      states: {
        idle: EmptyObject;
        fetchingNetworks: EmptyObject;
        hasNetworks: EmptyObject;
      };
    };
    communityQueryManager: {
      states: {
        idle: EmptyObject;
        fetchingCommunity: EmptyObject;
        hasCommunity: EmptyObject;
      };
    };
  };
}

const defaultContext = {
  bodyTag: null,
  communityResult: null,
  entrezList: [],
  giantVersion: 'v2',
  hasError: false,
  integrations: [],
  relevantNetworks: [],
  selectedNetworkSlug: null,
  selectedTermDbSlug: null,
  title: null,
};
const reset = {
  target: 'idle',
  actions: ['resetContext'],
};

export const fmdMachineConfig: MachineConfig<
  FMDMachineContext,
  FMDStateSchema,
  FMDMachineEvent
> = {
  schema: {
    context: {} as FMDMachineContext,
    events: {} as FMDMachineEvent,
  },
  id: 'fmd',
  type: 'parallel',
  context: defaultContext,
  states: {
    networksManager: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            RESET: reset,
            // Fetch networks in case we are loading from api cache/browser refresh
            'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]': {
              target: 'fetchingNetworks',
            },
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
              {
                cond: 'needsNetworks',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
            SELECT_GIANT_VERSION: {
              actions: ['assignGiantVersion'],
              target: 'fetchingNetworks',
            },
          },
        },
        fetchingNetworks: {
          entry: ['clearError'],
          on: {
            RESET: reset,
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
            SELECT_GIANT_VERSION: {
              actions: ['assignGiantVersion'],
              target: 'fetchingNetworks',
            },
          },
          invoke: {
            src: 'fetchCommunityNetworks',
            onDone: {
              actions: [
                'assignRelevantNetworksAndIntegrations',
                'assignSelectedNetworkSlug',
                'assignBodyTag',
              ],
              target: 'hasNetworks',
            },
            onError: {
              actions: 'setError',
              target: 'idle',
            },
          },
        },
        hasNetworks: {
          on: {
            RESET: reset,
            UPDATE_ENTREZ_LIST: [
              {
                cond: 'hasEntrezListChanged',
                actions: ['assignEntrezList', 'assignBodyTag'],
                target: 'fetchingNetworks',
              },
            ],
            SELECT_GIANT_VERSION: {
              actions: ['assignGiantVersion'],
              target: 'fetchingNetworks',
            },
          },
        },
      },
    },
    enrichmentDbManager: {
      initial: 'idle',
      states: {
        idle: {
          on: {
            RESET: reset,
            UPDATE_SELECTED_TERM_DB: {
              actions: ['assignSelectedTermDbSlug'],
              target: 'idle',
            },
          },
        },
      },
    },
    communityQueryManager: {
      initial: 'idle',
      states: {
        idle: {
          always: [
            {
              // If we have the same integration and entrez list as the communityResult query,
              //  we have the community data
              cond: (context: FMDMachineContext): boolean => {
                const {
                  entrezList,
                  giantVersion,
                  selectedNetworkSlug,
                  communityResult,
                  title,
                } = context;
                const query = communityResult?.query;
                const queryIntegrationSlug = query?.integration;
                const queryEntrezList = query?.entrez_list;
                const isSameGiantVersion =
                  giantVersion === query?.giant_version;
                const isSameTitle = title === (query?.title ? query.title : '');
                const isSameQuerySlug =
                  queryIntegrationSlug === selectedNetworkSlug;
                const isSameEntrezList =
                  entrezList && queryEntrezList
                    ? areEntrezListsEqual(entrezList, queryEntrezList)
                    : false;
                return (
                  isSameQuerySlug &&
                  isSameEntrezList &&
                  isSameTitle &&
                  isSameGiantVersion
                );
              },
              target: 'hasCommunity',
            },
          ],
          on: {
            FETCH_COMMUNITY: [
              {
                cond: 'fetchHasEntrezAndTissueNetwork',
                actions: [
                  'assignBodyTag',
                  'assignSelectedNetworkSlug',
                  'assignSelectedTermDbSlug',
                ],
                target: 'fetchingCommunity',
              },
              {
                target: 'fetchingCommunity',
                actions: ['assignBodyTag'],
              },
            ],
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetworkSlug', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
          },
        },
        fetchingCommunity: {
          entry: ['clearError'],
          on: {
            RESET: reset,
            FETCH_COMMUNITY: {
              target: 'fetchingCommunity',
              actions: [
                'assignBodyTag',
                'assignSelectedNetworkSlug',
                'assignSelectedTermDbSlug',
              ],
            },
            // SEEK UI makes UPDATE_ENTREZ_LIST relevant here
            UPDATE_ENTREZ_LIST: {
              target: 'idle',
            },
            // SEEK UI makes SELECT_GIANT_VERSION relevant here
            SELECT_GIANT_VERSION: {
              target: 'idle',
            },
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetworkSlug', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
          },
          invoke: {
            src: 'fetchCommunity',
            onDone: {
              actions: [
                'assignCommunity',
                'assignEntrezList',
                'assignGiantVersion',
                'assignSelectedNetworkSlug',
                'assignSelectedTermDbSlug',
                'assignTitle',
              ],
              target: 'hasCommunity',
            },
            onError: {
              actions: 'setError',
              target: 'idle',
            },
          },
        },
        hasCommunity: {
          on: {
            RESET: reset,
            FETCH_COMMUNITY: {
              target: 'fetchingCommunity',
              actions: ['assignBodyTag'],
            },
            // SEEK UI makes UPDATE_ENTREZ_LIST relevant here
            UPDATE_ENTREZ_LIST: {
              target: 'idle',
            },
            // SEEK UI makes SELECT_GIANT_VERSION relevant here
            SELECT_GIANT_VERSION: {
              target: 'idle',
            },
            UPDATE_SELECTED_NETWORK: {
              actions: ['assignSelectedNetworkSlug', 'assignBodyTag'],
              target: 'idle',
            },
            UPDATE_TITLE: {
              actions: ['assignTitle', 'assignBodyTag'],
              target: 'idle',
            },
          },
        },
      },
    },
  },
};

export const fmdMachineOptions: MachineOptions<
  FMDMachineContext,
  FMDMachineEvent
> = {
  activities: {},
  delays: {},
  guards: {
    fetchHasEntrezAndTissueNetwork: (_, event) => {
      if (event.type === 'FETCH_COMMUNITY') {
        return !!(event.entrezList && event.selectedNetworkSlug);
      }
      return false;
    },
    hasEntrezListChanged: (context, event) => {
      if (event.type === 'UPDATE_ENTREZ_LIST') {
        return (
          JSON.stringify(context.entrezList.sort()) !==
          JSON.stringify(event.entrezList.sort())
        );
      }
      return false;
    },
    needsNetworks: (context, event) => {
      if (event.type === 'UPDATE_ENTREZ_LIST') {
        return (
          // We have entrez, but no integrations or networks
          context.integrations.length === 0 &&
          (context.entrezList.length > 0 || event.entrezList.length > 0)
        );
      }
      return false;
    },
  },
  services: {
    fetchCommunityNetworks: async (context: FMDMachineContext) => {
      const { entrezList, giantVersion } = context;
      const communityGenes = entrezList.map((entrez: string) => ({ entrez }));

      const relevantNetworksPromise =
        giantVersion === 'v1'
          ? fetchRelevantNetworks(communityGenes).then(res => res.json())
          : Promise.resolve([]);

      const integrationsPromise = fetch(
        `${BASE_URI}integrations/?_=${HB_API_VERSION}`,
      ).then(res => res.json());

      const [relevantNetworks, integrations] = await Promise.all([
        relevantNetworksPromise,
        integrationsPromise,
      ]);

      return { integrations, relevantNetworks };
    },
    fetchCommunity: async (
      context: FMDMachineContext,
      event: FMDMachineEvent,
    ) => {
      const {
        bodyTag,
        entrezList,
        giantVersion,
        selectedNetworkSlug,
        title,
        communityResult: oldCommunityResult,
      } = context;

      if (event.type !== 'FETCH_COMMUNITY') return null;

      let communityApiUrl = '';
      let body = '';
      let tmpBodyTag = bodyTag;

      if (event.bodyTag) {
        tmpBodyTag = event.bodyTag;
        // Has cached result on server
        communityApiUrl = `${BASE_URI}integrations/community/?body_tag=${tmpBodyTag}`;
      } else if (giantVersion && tmpBodyTag) {
        // Is a new query
        communityApiUrl = `${BASE_URI}integrations/community/?integration=${selectedNetworkSlug}&giant_version=${giantVersion}&body_tag=${tmpBodyTag}${
          title?.length ? `&title=${title}` : ''
        }`;
        body = `{ "entrez": [${entrezList.map(
          (entrez: string) => `"${entrez}"`,
        )}] }`;
      } else {
        // Is an old GIANT-v1 query before v2 was available, needs updating
        const { query } = oldCommunityResult;
        const {
          title: cachedTitle,
          entrez_list: cachedEntrezList,
          integration: cachedIntegrationSlug,
        } = query;

        const updatedIntegrationSlug = `${cachedIntegrationSlug}-v1`;
        const entrezListFormatted = cachedEntrezList.map((entrez: string) => ({
          entrez,
        }));
        // The latest `bodyTag` relies on the GIANT version
        tmpBodyTag = getCommunityBodyTag(
          entrezListFormatted,
          updatedIntegrationSlug,
          'v1',
          cachedTitle || '',
        );

        communityApiUrl = `${BASE_URI}integrations/community/?integration=${updatedIntegrationSlug}&giant_version=${
          event.giantVersion
        }&body_tag=${tmpBodyTag}${
          cachedTitle?.length ? `&title=${cachedTitle}` : ''
        }`;
        body = `{ "entrez": [${cachedEntrezList.map(
          (entrez: string) => `"${entrez}"`,
        )}] }`;
      }

      const communityResult = await fetch(communityApiUrl, {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
      }).then(res => res.json());
      return { communityResult, bodyTag: tmpBodyTag };
    },
  },
  actions: {
    assignBodyTag: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (event.type === 'FETCH_COMMUNITY' && event.bodyTag)
          return { bodyTag: event.bodyTag };

        let tmpEntrezList = context.entrezList;
        let tmpSelectedNetworkSlug: string | null =
          context.selectedNetworkSlug || context.relevantNetworks?.[0]?.slug;
        let tmpTitle = context?.title || '';
        let tmpGiantVersion = context?.giantVersion || '';

        if (event.type === 'FETCH_COMMUNITY' && event.entrezList) {
          tmpEntrezList = event.entrezList;
          if (event.selectedNetworkSlug) {
            tmpSelectedNetworkSlug = event.selectedNetworkSlug;
          }
          if (event.title) tmpTitle = event.title;
          if (event.giantVersion) tmpGiantVersion = event.giantVersion;
        }

        if (event.type === 'UPDATE_ENTREZ_LIST') {
          const { entrezList } = event;
          tmpEntrezList = entrezList;
        }

        if (event.type === 'UPDATE_SELECTED_NETWORK') {
          const { selectedNetworkSlug } = event;
          tmpSelectedNetworkSlug = selectedNetworkSlug;
        }

        if (event.type === 'UPDATE_TITLE') {
          const { title } = event;
          tmpTitle = title || '';
        }

        if (event.type === 'SELECT_GIANT_VERSION') {
          const { giantVersion } = event;
          tmpGiantVersion = giantVersion || '';
        }

        const bodyTag =
          tmpSelectedNetworkSlug && tmpEntrezList.length > 0
            ? getCommunityBodyTag(
                tmpEntrezList.map(
                  (entrez: string) => ({ entrez }), // format for `getCommunityBodyTag`
                ),
                tmpSelectedNetworkSlug,
                tmpGiantVersion,
                tmpTitle,
              )
            : null;
        return { bodyTag };
      },
    ),
    assignCommunity: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          const { communityResult } = event.data;
          return { communityResult };
        }
        throw Error('Wrong event type name :: assignCommunity');
      },
    ),
    assignRelevantNetworksAndIntegrations: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (
          event.type ===
          'done.invoke.fmd.networksManager.fetchingNetworks:invocation[0]'
        ) {
          const { communityResult, giantVersion } = context;
          const currentDb = giantVersion === 'v1' ? TISSUE_DB : UBERON_DB;
          const integration = communityResult?.query?.integration;
          const { integrations, relevantNetworks } = event.data;
          const filteredIntegrations = filterTissueIntegrations(
            integrations,
            currentDb,
          );
          const filteredRelevantNetworks = filterTissueIntegrations(
            relevantNetworks,
            currentDb,
          );

          return {
            ...context,
            integrations: filteredIntegrations,
            relevantNetworks: filteredRelevantNetworks,
            selectedNetworkSlug:
              // Assign the current query slug if a result is present
              filteredIntegrations.find(item => item.slug === integration)
                ?.slug ||
              // If selectedNetworkSlug explicitly set by FETCH_COMMUNITY
              filteredRelevantNetworks.find(item => item.slug === integration)
                ?.slug ||
              // Otherwise use top relevant network as default
              filteredRelevantNetworks[0]?.slug,
          };
        }
        throw Error(
          'Wrong event type name :: assignRelevantNetworksAndIntegrations',
        );
      },
    ),
    assignEntrezList: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (event.type === 'UPDATE_ENTREZ_LIST') {
          const { entrezList } = event;
          return {
            entrezList,
            integrations: [],
            relevantNetworks: [],
            selectedNetworkSlug: null,
          };
        }

        if (event.type === 'FETCH_COMMUNITY') {
          const { entrezList } = event;
          return { entrezList };
        }

        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          const { communityResult } = event.data;
          const entrezList = communityResult?.query?.entrez_list.map(
            (entrez: string) => Number(entrez),
          );
          return { entrezList };
        }

        return { entrezList: context.entrezList };
      },
    ),
    assignGiantVersion: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          const { communityResult } = event.data;
          // If the field is missing from query, must be old result, default to v1
          const giantVersion = communityResult?.query?.giant_version || 'v1';
          return { giantVersion };
        }
        if (event.type === 'SELECT_GIANT_VERSION') {
          const { giantVersion } = event;
          return { giantVersion };
        }
        return {
          giantVersion: context.giantVersion,
          selectedNetworkSlug: null,
        };
      },
    ),
    assignSelectedNetworkSlug: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (event.type === 'UPDATE_SELECTED_NETWORK') {
          const { selectedNetworkSlug } = event;
          return { selectedNetworkSlug };
        }
        if (event.type === 'FETCH_COMMUNITY' && event.selectedNetworkSlug) {
          const { selectedNetworkSlug } = event;
          return { selectedNetworkSlug };
        }
        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          // In the case where the back / forward buttons fetch a new communityResult
          //  and we need to update selectedNetworkSlug to match once the result is received
          const queryIntegrationSlug =
            event?.data?.communityResult?.query?.integration;

          // Update the integration slug to append -v1 if it is missing a version #
          //  this is to accommodate older queries from before the GIANT version
          const versionedIntegration = /-v\d+$/.test(queryIntegrationSlug)
            ? queryIntegrationSlug
            : `${queryIntegrationSlug}-v1`;

          return {
            selectedNetworkSlug: versionedIntegration,
          };
        }

        return { selectedNetworkSlug: context.selectedNetworkSlug };
      },
    ),
    assignSelectedTermDbSlug: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (event.type === 'UPDATE_SELECTED_TERM_DB') {
          const { selectedTermDbSlug } = event;
          return { selectedTermDbSlug };
        }
        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          const {
            communityResult: { enrichment },
          } = event.data;

          let maxLength = 0;
          let dbSlugWithMostTerms = 'gene-ontology-bp';
          Object.keys(enrichment).forEach(key => {
            if (enrichment[key].length > maxLength) {
              maxLength = enrichment[key].length;
              dbSlugWithMostTerms = key;
            }
          });
          return {
            selectedTermDbSlug:
              // Default is GO unless GO has no enriched terms.
              enrichment['gene-ontology-bp'].length > 0
                ? 'gene-ontology-bp'
                : dbSlugWithMostTerms,
          };
        }

        return { selectedNetworkSlug: context.selectedNetworkSlug };
      },
    ),
    assignTitle: assign(
      (context: FMDMachineContext, event: FMDMachineEvent) => {
        if (event.type === 'UPDATE_TITLE') {
          const { title } = event;
          return { title };
        }
        if (event.type === 'FETCH_COMMUNITY' && event.title) {
          const { title } = event;
          return { title };
        }
        if (
          event.type ===
          'done.invoke.fmd.communityQueryManager.fetchingCommunity:invocation[0]'
        ) {
          // In the case where the back / forward buttons fetch a new communityResult
          //  and we need to update title to match once the result is received
          const { communityResult } = event.data;
          const communityResultTitle = communityResult?.query?.title;
          return { title: communityResultTitle || '' };
        }

        return { title: context.title };
      },
    ),
    resetContext: assign(() => {
      // TODO: allow option to preserve specific context keys
      // TODO: test how many times this action runs since there are many parallel machines
      return defaultContext;
    }),
    setError: assign(() => {
      return { hasError: true };
    }),
    clearError: assign(() => {
      return { hasError: false };
    }),
  },
};

const fmdMachine = createMachine(fmdMachineConfig, fmdMachineOptions);
export default fmdMachine;
